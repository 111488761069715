import _ from 'lodash';

const initialState = {
  count: 0,
  offset: 0,
  limit: 2,
  properties: [],
  category: null,
  location: null,
  status: null,
  priceMin: null,
  priceMax: null,
  beds: null,
  baths: null,
  searchTerm: '',
  filterToggle: false,
  filterTriggerFromNav: 0,
  sentFromNav: false,
};

const reducer = (state = initialState, action) => {
  if (action.type === 'RESET') {
    return {
      ...state,
      offset: 0,
      properties: [],
    };
  }

  if (action.type === `SCROLL`) {
    const {
      properties,
      category,
      location,
      status,
      priceMin,
      priceMax,
      beds,
      baths,
      searchTerm,
      offset,
      limit,
    } = state;
    if (action.all_properties.length > properties.length) {
      const propertiesWithUserFilters = [
        ...action.all_properties
          .filter(p =>
            category && category.value != ''
              ? p.category == category.value
              : true,
          )
          .filter(p =>
            location && location.value != ''
              ? p.location == location.value
              : true,
          )
          .filter(p =>
            status && status.value != '' ? p.status == status.value : true,
          )
          .filter(p =>
            priceMin && priceMin.value != ''
              ? p.weekly_rent && p.weekly_rent >= parseInt(priceMin.value)
              : true,
          )
          .filter(p =>
            priceMax && priceMax.value != ''
              ? p.weekly_rent && p.weekly_rent <= parseInt(priceMax.value)
              : true,
          )
          .filter(p =>
            beds && beds.value != ''
              ? parseInt(beds.value) == 5
                ? p.bedrooms >= 5
                : p.bedrooms == parseInt(beds.value)
              : true,
          )
          .filter(p =>
            baths && baths.value != ''
              ? parseInt(baths.value) == 3
                ? p.bathrooms >= 3
                : p.bathrooms == parseInt(baths.value)
              : true,
          )
          .filter(p =>
            searchTerm.length > 1
              ? p.street_address
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
              : true,
          ),
      ];
      return {
        ...state,
        offset: offset + limit,
        properties: [
          ...properties,
          ...[
            ...propertiesWithUserFilters.filter(p => p.status == 'available'),
            ...propertiesWithUserFilters.filter(p => p.status != 'available'),
          ].slice(offset, offset + limit),
        ],
        needsReload: false,
      };
    }
  }

  if (action.type === `FILTER`) {
    return {
      ...state,
      [action.key]: action.value,
      properties: [],
      offset: 0,
    };
  }

  if (action.type === `FILTER_FROM_NAV`) {
    return {
      ...state,
      [action.key]: action.value,
      properties: [],
      offset: 0,
      sentFromNav: true, // Append this, to trigger the useEffect on the homepageGrid
    };
  }

  if (action.type === 'RESET_SENT_FROM_NAV') {
    return {
      ...state,
      sentFromNav: false,
    };
  }

  if (action.type === `FILTER_TOGGLE`) {
    // This is currently active
    if (state.filterToggle) {
      return {
        ...state,
        properties: [],
        offset: 0,
        category: null,
        location: null,
        status: null,
        priceMin: null,
        priceMax: null,
        beds: null,
        baths: null,
        searchTerm: '',
        filterToggle: false,
      };
    }
    return {
      ...state,
      filterToggle: true,
    };
  }
  return state;
};

export default reducer;
